import Epub, { EpubCFI } from "epubjs";
import MobileDetect from "mobile-detect";
import { isEmpty } from "@/views/common/web/isEmpty";
import {
    postEPubLastReadInfo,
    getEPubTeacherReadConfig,
    postTeacherNote,
    getTeacherNotes,
    deleteTeacherNotes,
    updateTeacherNotes,
} from "@/api/library";
import {
    getBookDetail,
} from "@/api/api-pc";
import { getBookAudioData } from "@/api/api-reader.js";

class TeacherReaderUtil {
    data() {
        return {
            showCLoading: true,
            bookId: 147,
            bookCfi: null, //传递过来的Cfi
            readWordNum: 0, //阅读过的字数
            readStartTime: "", //进入时的时间
            readEndTime: "", //退出时的时间
            readLastCfi: "", //退出时的Cfi
            bookInfo: {},
            showTopBar: true, //是否显示顶部栏
            showBottomBar: false, //是否显示底部栏
            statusBarHeight: 20,
            readHeight: window.innerHeight,
            defaultTheme: {
                name: "normal",
                barBackground: "white",
                style: {
                    body: {
                        color: "#444444",
                        background: "#F4F4F4",
                    },
                },
            }, //默认主题
            defaultFontSize: 16, //默认字号
            bookAvailable: false,
            progress: 0, //当前进度
            currentReadWordNum: 0, //当前页阅读字数
            currentReadStartTime: null, //当前页阅读开始时间
            currentReadEndTime: null, //当前页阅读结束时间
            currentReadStayTime: 0, //当前页阅读停留时间
            navigation: null,
            htmlStr: "", //富文本字符串
            canScroll: true, //能否滑动
            pageChange: 0, //0 非手势 1 右滑上一页 2 左滑 下一页
            showHtmlStr: false, //是否显示富文本
            touchStart: null,
            touchEnd: null,
            page: 1,
            totalPage: 0,
            contents: null,
            pageChange: 0, //0 pre 1 next
            isLastPage: false,

            //以下为 高亮、笔记 相关数据

            selectedCfi: null, //选中后的cfiRange
            selectedAnnotation: {
                data: {},
            },
            isMarkClick: false, //控制与click
            notes: [], //学生笔记
            textMenu: {
                showTextMenu: false,
                x: 0,
                y: 0,
                isUnderLined: false,
            },

            markClickCount: 0,
            currentLineHeight: 22,
            //笔记输入框属性
            noteText: "",
            showNoteInput: false,
            noteInputFrom: 0, //0 选中 1//点击tip 2 羽毛按钮
            selectedText: "",
            showTeacherNote: false,

            isFirstLoad: true,
            hasReadMin: 0,

            isShowNoteComments: false,
            comments: [],
            isShowNoteBtn: false,

            showPrev: false,//章节按钮判断PC端
            showNext: false,//章节按钮判断PC端

            showPinyin: false,
            pinyinText: '',

            showAudioPlayer: false,
            audioData: [],
        };
    }


    provide() {
        return {
            getStatusBarHeight: () => this.statusBarHeight,
            getRendition: () => this.rendition,
            getNavigation: () => this.navigation,
            getLocations: () => this.locations,
            getBookAvailable: () => this.bookAvailable,
            getThemes: () => this.themes,
            getNotes: () => this.notes,
            hideDrawer: () => this.hideBar(),
            setNotes: (notes) => (this.notes = notes),
            isTeacher: true,
            showNoteBtn: (visible) => {
                this.isShowNoteBtn = visible;
            },
            //
            setComments: (comments) => {
                this.comments = comments;
            },
            getComments: () => this.comments,
            showNoteComments: () => {
                this.showTeacherNote = false;
                this.$nextTick(() => {
                    this.isShowNoteComments = true;
                });
            },
            showCommentTextField: () => { },
        };
    }

    computed = {
        menuClass() {
            const classObject = {};
            Object.assign(classObject, { "margin-left": "40px" });
            return classObject;
        },
        selectedAnnotationNote() {
            if (this.selectedAnnotation != null) {
                return this.selectedAnnotation.data.note;
            } else {
                return ""; //this.selectedAnnotation.data.note
            }
        },
        isIOSFullFaceScreen() {
            const md = new MobileDetect(window.navigator.userAgent);
            const os = md.os()
            if (os == "iOS" && this.statusBarHeight > 28) {
                return true
            } else {
                return false
            }
        }
    }

    methods = {
        getConfig() {
            getEPubTeacherReadConfig()
                .then((res) => {
                    this.showTopBar = false;
                    this.showCLoading = false;
                    if (!res) {
                        console.log(">>>>>getEPubTeacherReadConfig错误");
                        this.$refs.bottomBar.setFontSize("16px", true);
                        this.$refs.bottomBar.setTheme(0, true);
                    } else {
                        console.log(">>>>>getEPubTeacherReadConfig成功");
                        this.$refs.bottomBar.setFontSize(res.epubFontSize, true);
                        setTimeout(() => {
                            switch (res.epubTheme) {
                                case "normal":
                                    this.$refs.bottomBar.setTheme(0, true);
                                    break;
                                case "eyeCaring":
                                    this.$refs.bottomBar.setTheme(1, true);
                                    break;
                                case "green":
                                    this.$refs.bottomBar.setTheme(2, true);
                                    break;
                                case "dark":
                                    this.$refs.bottomBar.setTheme(3, true);
                                    break;
                            }
                        }, 50);
                    }
                })
                .catch(({ res, statusCode }) => {
                    this.showTopBar = false;
                    this.showCLoading = false;
                    console.log(">>>>>getEPubTeacherReadConfig失败");
                    this.$refs.bottomBar.setFontSize("16px", true);
                    this.$refs.bottomBar.setTheme(0, true);
                    if (!res || !res.message) {
                        return;
                    }

                    this.showSnackbar({ msg: res.message });
                });
        },
        getBookInfo() {
            getBookDetail(this.bookId)
                .then((res) => {
                    if (!res) {
                        console.log(">>>>>getEPubBookInfo错误");
                    } else {
                        console.log(">>>>>getEPubBookInfo成功");
                        this.bookInfo = res;
                        if (this.bookInfo && this.bookInfo.epubUrl) {
                            this.showEpub(this.bookInfo.epubUrl);
                        }
                    }
                })
                .catch(({ res, statusCode }) => {
                    console.log(">>>>>getEPubBookInfo失败");
                    if (!res || !res.message) {
                        return;
                    }

                    this.showSnackbar({ msg: res.message });
                });
        },
        postLastBookInfo() {
            postEPubLastReadInfo(
                this.bookId,
                0,
                this.readWordNum,
                this.readStartTime,
                this.readEndTime,
                this.readLastCfi,
                this.progress
            )
                .then((res) => {
                    if (!res) {
                        console.log(">>>>>postEPubLastReadInfo错误");
                    } else {
                        console.log(">>>>>postEPubLastReadInfo成功");
                    }
                    this.$native.close();
                })
                .catch(({ code, msg }) => {
                    console.log(">>>>>postEPubLastReadInfo失败");
                    this.$native.close();
                    if (!msg) {
                        return;
                    }

                    this.showSnackbar({ msg });
                });
        },
        showEpub(url) {
            //解析电子书,生成Book对象
            this.book = new Epub(url);
            //通过Book.renderTo生成Rendition对象（对象名不唯一），第一个参数是div的id
            this.rendition = this.book.renderTo("read", {
                width: window.innerWidth,
                height: this.readHeight,
                spreadWidth: window.innerWidth,
            });

            //获取Theme对象
            this.themes = this.rendition.themes;

            this.book.ready
                .then(() => {
                    this.bookAvailable = true;
                    this.currentReadStartTime = this.$moment().format(
                        "YYYY-MM-DD HH:mm:ss"
                    );
                    //跳转到上次的位置
                    if (this.bookCfi) {
                        this.jumpLast(this.bookCfi);
                    }
                    //获取在线配置
                    this.getConfig();
                    //生成目录
                    this.navigation = this.book.navigation;
                    // let wordsCount =
                    //   (window.innerWidth * window.innerHeight) /
                    //   (this.currentLineHeight * this.defaultFontSize);
                    return this.book.locations.generate();
                })
                .then((result) => {
                    console.log("阅读器>>>", "准备完成");
                    this.locations = this.book.locations;
                    this.setProgress(this.rendition.location.start.cfi);
                    this.page =
                        this.rendition.book.locations.locationFromCfi(
                            this.rendition.location.start.cfi
                        ) + 1;
                });

            this.rendition.on("locationChanged", (e) => {
                console.log(this.rendition.location.start);
                console.log(this.rendition.currentLocation().start);
                this.closeTextMenus();
                this.setProgress(e.start);

                switch (this.pageChange) {
                    case 0:
                        this.page =
                            this.rendition.book.locations.locationFromCfi(e.start) + 1;
                        break;
                    case 1:
                        if (this.page > 1) {
                            this.page--;
                        }
                        this.pageChange = 0;
                        break;
                    case 2:
                        if (!this.isLastPage) {
                            this.page++;
                        }
                        this.pageChange = 0;
                        break;
                }

                this.getIsLastPage(e);
            });

            this.rendition.on("displayed", (e) => {
                console.log(this.rendition);

                if (this.isFirstLoad) {
                    this.handleBookReady();
                    setInterval(() => {
                        this.hasReadMin++;
                    }, 60000);
                    this.isFirstLoad = false;
                    this.totalPage = this.rendition.book.locations.total;
                    this.getAudioUrls(this.bookId)
                }
            });



            this.rendition.on("selected", (cfiRange, contents) => {
                this.unhighlightText(this.selectedCfi);
                this.selectedCfi = cfiRange;
                this.contents = contents;
                let range = this.rendition.getRange(cfiRange);
                this.selectedAnnotation = this.highlightText(
                    cfiRange,
                    "#4D9F7C",
                    "0.4"
                );
                this.noteInputFrom = 0;
                this.handleSelectEnd();
            });

            this.rendition.on("click", () => {
                if (this.isMarkClick == false) {
                    if (this.selectedCfi != null) {
                        this.hideBar();
                    } else {
                        this.toggleBar();
                    }
                    this.closeTextMenus();
                }
                this.isMarkClick = false;
            });

            this.rendition.on("markClicked", (cfiRange, data, contents) => {
                contents.window.getSelection().removeAllRanges();
                this.unhighlightText(this.selectedCfi);
                this.isMarkClick = true;
                if (this.markClickCount == 0) {
                    this.markClickCount++;
                } else {
                    this.handleMarkClicked(cfiRange, data.contents);
                    this.markClickCount = 0;
                    this.isMarkClick = false;
                }
            });

            //触摸调用翻页
            this.rendition.on("touchstart", (e) => {
                console.log("阅读器>>>>>", "触摸开始");
                this.touchStart = e.changedTouches[0];
            });
            //触摸调用翻页
            this.rendition.on("touchend", (e) => {
                console.log("阅读器>>>>>", "触摸结束");
                this.touchEndHandler(e);
            });

            //通过Rendtion.display渲染电子书
            this.rendition.display();
        },
        handleBookReady() {
            this.loadTeacherNotes();
        },
        //加载笔记
        loadTeacherNotes() {
            getTeacherNotes(this.bookId).then((res) => {
                this.notes = res.notes;
                this.notes.map((note) => {
                    if (note.epubcfi) {
                        this.underLineText(note.epubcfi, "#4D9F7C", note);
                    }
                });
            });
        },
        touchEndHandler(e) {
            if (!this.selectedCfi) {
                this.swipePage(e);
            }
        },
        swipePage(e) {
            this.touchEnd = e.changedTouches[0];
            if (this.touchEnd.screenX - this.touchStart.screenX > 10) {
                if (this.canScroll) {
                    console.log("阅读器>>>>>", "上一页");
                    this.rendition.prev();
                    this.hideBar();
                    this.canScroll = false;
                    this.setCurrentReadStayTime("previou");
                    this.pageChange = 1;
                }
                setTimeout(() => {
                    this.canScroll = true;
                }, 300);
            }
            if (this.touchStart.screenX - this.touchEnd.screenX > 10) {
                if (this.canScroll) {
                    console.log("阅读器>>>>>", "下一页");
                    this.rendition.next();
                    this.hideBar();
                    this.canScroll = false;
                    this.setCurrentReadStayTime("next");
                    this.pageChange = 2;
                }
                setTimeout(() => {
                    this.canScroll = true;
                }, 300);

                if (this.isLastPage) {
                    this.showDialog({
                        text: "您已读完本书，是否退出阅读？",
                        icon: "success",
                        //自定义按钮，按钮最多两项
                        btns: [
                            {
                                text: "确定",
                                cbk: () => {
                                    this.readEndTime = this.$moment().format(
                                        "YYYY-MM-DD HH:mm:ss"
                                    );
                                    this.readLastCfi = this.rendition.location.end.cfi;
                                    this.postLastBookInfo();
                                    this.closeDialog();
                                },
                                color: "primary",
                            },
                            {
                                text: "取消",
                                cbk: () => {
                                    this.closeDialog();
                                    console.log("取消按钮回调");
                                },
                                color: "red",
                            },
                        ],
                    });
                }
            }
        },
        //选中完成后处理
        handleSelectEnd() {
            if (this.selectedCfi == null) {
                return;
            }

            // let cfiRange = this.rendition.getRange(this.selectedCfi);
            this.showTextMenus(this.selectedCfi, false);
        },
        //笔记点击后处理
        handleMarkClicked(cfiRange, data, contents) {
            this.textMenu.showTextMenu = false;
            let anns = _.filter(this.rendition.annotations._annotations, (ann) => {
                return ann.cfiRange == cfiRange;
            });
            let annotation = _.first(anns);
            this.selectedAnnotation = annotation;
            this.selectedText = this.getNoteString(annotation.cfiRange);
            if (annotation.data.note != undefined && annotation.data.note != "") {
                this.showTeacherNoteTip(annotation);
            } else {
                this.showTextMenus(annotation.cfiRange, true);
            }

            if (annotation.data.id) {
                //埋点click_note
                this.$track.event({
                    category: "reader",
                    name: "click_note",
                    value: annotation.data.id,
                    params: {
                        source_page: "book_page",
                    },
                });
            }
        },
        showTeacherNoteTip(annotation) {
            this.showTeacherNote = true;
        },
        reDrawMarks(notes) {
            _.forEach(notes, (note) => {
                this.rendition.annotations.remove(note.epubcfi, "underline");
                this.underLineText(note.epubcfi, "#4D9F7C", note);
            });
        },
        //页面切换加载笔记
        loadUnderlineAnnotations(index) {
            let annotations = _.filter(
                this.rendition.annotations._annotations,
                (annotation) => {
                    return (
                        annotation.sectionIndex == index && annotation.type == "underline"
                    );
                }
            );
            _.forEach(annotations, (annotation) => {
                this.unUnderlineText(annotation.cfiRange, {});
                this.underLineText(annotation.cfiRange, "#4D9F7C", annotation.data);
            });
        },
        //显示划线 笔记 复制菜单
        //显示划线 笔记 复制菜单
        showTextMenus(cfiRange, marked) {
            this.textMenu.isUnderLined = marked;
            let range = this.rendition.getRange(cfiRange);
            let frame = range.getBoundingClientRect();
            let curPage = this.rendition.location.start.displayed.page
            let triangleHeight = this.isIOSFullFaceScreen ? 18 : 0
            this.textMenu.x = frame.x - window.innerWidth * (curPage - 1) + frame.width / 2 - 16
            this.textMenu.y = frame.y + frame.height + 28 + triangleHeight
            var marginLeft = 4;
            if (this.textMenu.x > (window.innerWidth - 250)) {
                marginLeft = this.textMenu.x - (window.innerWidth - 250) + 16;
            }
            this.textMenu.marginLeft = marginLeft;
            this.textMenu.width = frame.width;
            this.textMenu.showTextMenu = true;
            this.selectedText = this.getNoteString(cfiRange);
        },
        //关闭 划线 笔记 复制 菜单
        closeTextMenus() {
            this.textMenu.showTextMenu = false;
            this.textMenu.isUnderLined = false;
            this.$nextTick(() => {
                if (
                    this.selectedAnnotation != null &&
                    this.selectedAnnotation.type == "highlight"
                ) {
                    this.rendition.annotations.remove(
                        this.selectedAnnotation.cfiRange,
                        "highlight"
                    );
                }
                this.selectedText = "";
                this.selectedCfi = null;
                this.selectedAnnotation = null;
            });
        },
        noteBtnClickHandler() {
            this.closeTextMenus();
            this.noteText = "";
            this.noteInputFrom = 2;
            this.showNoteInput = true;
            this.hideBar();
        },
        //朗读
        playSound() {
            this.hideBar()
            this.showAudioPlayer = true
        },
        unhighlightText(cfiRange) {
            this.rendition.annotations.remove(cfiRange, "highlight");
        },
        unUnderlineText(cfiRange) {
            this.rendition.annotations.remove(cfiRange, "underline");
        },
        highlightText(cfiRange, color, opacity) {
            let annotation = this.rendition.annotations.add(
                "highlight",
                cfiRange,
                { type: "highlight" },
                (e) => {
                    //callback
                },
                undefined,
                { fill: color, "fill-opacity": opacity }
            );
            return annotation;
        },
        handleAnnotion(method) {
            if (this.selectedCfi == null && this.selectedAnnotation == null) {
                return;
            }
            if (this.contents != null) {
                this.contents.window.getSelection().removeAllRanges();
            }

            var annotation = {}
            if (this.selectedAnnotation) {
                let cfiRange = this.selectedAnnotation.cfiRange;
                annotation = this.selectedAnnotation;
                this.rendition.annotations.remove(cfiRange, "highlight");
            }
            switch (method) {
                case "underline":
                    this.addUnderLineNote(annotation, "");
                    break;
                case "note":
                    this.showNoteTextField();
                    break;
                case "pinyin":
                    let cfi = annotation.cfiRange ? annotation.cfiRange : this.selectedCfi
                    let pinyinText = this.getNoteString(cfi).replace(/(^\s*)|(\s*$)/g, "")
                    if (pinyinText.length >= 400) {
                        this.showSnackbar({ msg: "选择字数过多" });
                        this.closeTextMenus()
                        return
                    }
                    this.pinyinText = pinyinText
                    this.showPinyin = true
                    this.closeTextMenus()
                    break;
                case "copy":
                    this.copy();
                    break;
                case "delete":
                    this.deleteAnnotation();
                    break;
            }
        },
        deleteAnnotation() {
            this.rendition.annotations.remove(
                this.selectedAnnotation.cfiRange,
                "underline"
            );
            if (this.selectedAnnotation.data.id != "") {
                const noteId = this.selectedAnnotation.data.id;
                deleteTeacherNotes(this.bookId, noteId).then((res) => {
                    _.remove(this.notes, ({ id }) => {
                        return id == noteId;
                    });
                    this.$root.$emit("noteRemoved");
                });
                this.closeTextMenus();
            }
        },
        showNoteTextField() {
            this.showNoteInput = true;
            if (this.noteInputFrom == 0) {
                this.noteText = "";
            }
        },
        bottomNoteEditBtnClickHandler(annotation) {
            this.showTeacherNote = false;
            this.noteInputFrom = 1;
            this.selectedAnnotation = annotation;
            this.noteText = annotation.data.note;
            this.selectedCfi = annotation.cfiRange;
            this.showNoteInput = true;
        },
        handleHideTextField() {
            this.showNoteInput = false;
            this.closeTextMenus();
        },
        handleSaveNote(note) {
            if (note) {
                this.showNoteInput = false;
                if (this.noteInputFrom == 2) {
                    this.addNoMarkNote(note);
                } else {
                    this.addUnderLineNote(this.selectedAnnotation, note);
                }
            }
        },
        addNoMarkNote(note) {
            postTeacherNote(this.bookId, "", "", note).then((note) => {
                this.notes.unshift(note);

                this.$track.event({
                    category: "reader",
                    name: "create_note",
                    value: note.id,
                    params: {
                        start_anchor: this.rendition.location.start.cfi,
                        end_anchor: this.rendition.location.end.cfi,
                        process: this.progress,
                    },
                });
            });
        },
        addUnderLineNote(annotation, note) {
            if (!annotation || !annotation.cfiRange) {
                annotation = { data: note };
                annotation.cfiRange = this.selectedCfi;
            }

            let cfiRange = annotation.cfiRange;
            let data = annotation.data;
            if (annotation.type == "underline") {
                this.unUnderlineText(cfiRange);
            } else {
                // this.underLineText(cfiRange, "#4D9F7C", { note: note });
            }

            if (data.id) {
                updateTeacherNotes(this.bookId, data.id, note).then(() => {
                    let updateNote = _.filter(this.notes, (n) => {
                        return n.id == data.id;
                    });
                    updateNote.note = note;
                    data.note = note;
                    this.underLineText(cfiRange, "#4D9F7C", data);
                });
            } else {
                let markedText = this.getNoteString(cfiRange);
                postTeacherNote(this.bookId, cfiRange, markedText, note).then(
                    (note) => {
                        this.notes.unshift(note);
                        data = note;
                        this.underLineText(cfiRange, "#4D9F7C", data);

                        this.$track.event({
                            category: "reader",
                            name: "create_note",
                            value: note.id,
                            params: {
                                start_anchor: this.rendition.location.start.cfi,
                                end_anchor: this.rendition.location.end.cfi,
                                process: this.progress,
                            },
                        });
                    }
                );
            }
            this.closeTextMenus();
        },
        underLineText(cfiRange, color, data) {
            if (!cfiRange) {
                return;
            }

            let annotation = this.rendition.annotations.add(
                "underline",
                cfiRange,
                data,
                (e) => { },
                undefined,
                { "stroke-width": "0" }
            );

            if (annotation.mark != null && annotation.mark.element != undefined) {
                this.changeUnderLineStyle(annotation.mark.element, color);
            }
        },

        changeUnderLineStyle(svg, color) {
            setTimeout(() => {
                let lines = svg.getElementsByTagName("line");
                svg.setAttribute("stroke-opacity", "1");
                _.forEach(lines, (line) => {
                    line.setAttribute("stroke", color);
                    line.setAttribute("stroke-width", "2px");
                });

                // let lastLine = _.last(lines)
                // var circle =  document.createElementNS('http://www.w3.org/2000/svg', 'circle')
                // var x2 = parseInt(lastLine.getAttribute('x2'))
                // x2 = x2 + 5
                // circle.setAttribute('cx',lastLine.getAttribute('x2'))
                // circle.setAttribute('cy',lastLine.getAttribute('y2'))
                // circle.setAttribute('r','5')
                // circle.setAttribute('fill','red')
                // circle.setAttribute('fill-opacity','0.3')
                // svg.appendChild(circle);
            }, 500);
        },
        getIsLastPage(e) {
            //判断是否最后一页
            let last = _.last(this.rendition.book.locations._locations);
            let lastArr = last.split(",");
            let lastEnd = lastArr[0] + lastArr[2];
            if (e.end == lastEnd) {
                this.isLastPage = true;
            } else {
                this.isLastPage = false;
            }
            console.log("isLastPage", this.isLastPage);
        },
        copy() {
            this.$native.copy(this.selectedText);
            this.closeTextMenus();
            //埋点copy_book
            this.$track.event({
                category: "reader",
                name: "copy_book",
                value: this.bookId.toString(),
                params: {
                    text: this.selectedText,
                },
            });
        },
        locateToHighlight(highlight) {
            this.rendition.display(highlight.location);
        },
        getNoteString(cfiRange) {
            let range = this.rendition.getRange(cfiRange);
            return range.toString();
        },
        setProgress(cfiStr) {
            // 获取当前位置的进度百分比
            let cfi = new EpubCFI().parse(cfiStr);
            this.progress = this.rendition.book.locations.percentageFromCfi(cfi);
            this.progress = Math.round(this.progress * 100);
            this.setReadWordNum();
        },
        toggleBar() {
            this.showTopBar = !this.showTopBar;
            this.showBottomBar = !this.showBottomBar;
            if (this.showTopBar && this.showBottomBar) {
                this.$track.event({
                    category: "reader",
                    name: "call_menu",
                    value: this.bookId.toString(),
                });
            } else if (this.$refs.bottomBar) {
                this.$refs.bottomBar.hideSetting();
            }
        },
        hideBar() {
            if (this.$refs.bottomBar) {
                this.showTopBar = false;
                this.showBottomBar = false;
                //隐藏菜单栏弹出的设置栏
                this.$refs.bottomBar.hideSetting();
                //隐藏目录
                this.$refs.bottomBar.hideContent();
            }
        },
        getFontSize(fontSize, lineHeight) {
            this.defaultFontSize = fontSize;
            this.currentLineHeight = lineHeight;
        },
        getTheme(theme) {
            this.defaultTheme = theme;
        },
        showTeacherSay() {
            this.hideBar();
            this.isTeacherSay = true;
            this.$track.event({
                category: "reader",
                name: "enter_teacher_note_list",
                value: this.bookId.toString(),
            });
        },
        //根据CFI跳转到上一次退出时的位置
        jumpLast(href) {
            this.rendition.display(href);
            this.hideBar();
        },
        //根据CFI跳转到指定章节
        jumpTo(index) {
            this.rendition.display(this.navigation.toc[index].href).then(() => {
                this.$track.event({
                    category: "reader",
                    name: "click_catalog",
                    value: this.navigation.toc[index].label.trim(),
                    params: {
                        start_anchor: this.rendition.location.start.cfi,
                        end_anchor: this.rendition.location.end.cfi,
                    },
                });
            });
            this.hideBar();
        },
        setCurrentReadStayTime(orientation) {
            this.currentReadEndTime = this.$moment().format("YYYY-MM-DD HH:mm:ss");
            if (this.currentReadStartTime && this.currentReadEndTime) {
                this.currentReadStayTime = this.$moment(this.currentReadEndTime).diff(
                    this.$moment(this.currentReadStartTime),
                    "seconds"
                );
                console.log("阅读器停留时间>>>", this.currentReadStayTime);
                this.currentReadStartTime = this.currentReadEndTime;
            }
            this.$track.event({
                category: "reader",
                name: "scroll_book",
                value: this.bookId,
                params: {
                    cost: this.currentReadStayTime,
                    word_count: this.currentReadWordNum,
                    direction: orientation,
                    start_anchor: this.rendition.location.start.cfi,
                    end_anchor: this.rendition.location.end.cfi,
                    process: this.progress,
                },
            });
        },
        setReadWordNum() {
            const startCfi = this.rendition.currentLocation().start.cfi;
            const endCfi = this.rendition.currentLocation().end.cfi;
            const cfiBase = startCfi.replace(/!.*/, "");
            const cfiStart = startCfi.replace(/.*!/, "").replace(/\)$/, "");
            const cfiEnd = endCfi.replace(/.*!/, "").replace(/\)$/, "");
            const cfiRange = `${cfiBase}!,${cfiStart},${cfiEnd})`;
            this.book.getRange(cfiRange).then((range) => {
                this.currentReadWordNum = range.toString().length;
                this.readWordNum = this.readWordNum + range.toString().length;
                console.log(
                    "阅读器>>>",
                    "当前页字数>>>",
                    this.currentReadWordNum,
                    "累计阅读字数>>>",
                    this.readWordNum
                );
            });
        },
        back() {
            if (this.bookAvailable) {
                this.readEndTime = this.$moment().format("YYYY-MM-DD HH:mm:ss");
                this.readLastCfi = this.rendition.location.end.cfi;
                this.postLastBookInfo();
            } else {
                this.$native.close();
            }
        },
        getAudioUrls(bookId) {
            getBookAudioData(bookId).then((res) => {
                this.axios.get(res.url).then((json) => {
                    this.audioData = json.data;
                });
            });
        },
    }

    watch = {
        page: function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.reDrawMarks(this.notes);
            }
        },
        showTeacherNote(val) {
            if (this.noteInputFrom == 0 && val == false) {
                this.closeTextMenus();
            }

            this.noteInputFrom = 0;
        },

        defaultFontSize: function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.reDrawMarks(this.notes);
            }
        },
        hasReadMin(val) {
            if (val % 15 == 0) {
                this.showSnackbar({ msg: val + "分钟到咯,注意保护眼睛哦～" });
            }
        },
        showAudioPlayer(val) {
            if (val) {
                _.forEach(this.notes, (note) => {
                    this.rendition.annotations.remove(note.epubcfi, "underline");
                });
            } else {

                this.reDrawMarks(this.notes);

            }
        },
    }

    created() {
        this.$native.onback().then(() => {
            this.back();
        });
        this.$native.getStatusBarHeight().then((height) => {
            this.statusBarHeight = height;
            this.readHeight = window.innerHeight - this.statusBarHeight * 2;
        });
        this.readStartTime = this.$moment().format("YYYY-MM-DD HH:mm:ss");
        if (!isEmpty(this.$route.query.bookId)) {
            this.bookId = parseInt(this.$route.query.bookId);
            this.$track.event({
                category: "reader",
                name: "enter_book",
                value: this.bookId.toString(),
            });
        }
        if (!isEmpty(this.$route.query.bookCfi)) {
            this.bookCfi = this.$route.query.bookCfi;
        }
    }

    mounted() {
        this.getBookInfo();
    }

    app_methods() {
        var methods = this.methods
        return methods
    }


    //以上app的所有控制层方法逻辑


    //下面是pc相关，需要个别方法重写
    pc_methods() {
        var methods = this.methods
        methods.showEpub = this.showPCEpub
        methods.showTextMenus = this.showPCTextMenus
        return methods
    }

    showPCEpub(url) {
        this.readHeight = window.innerHeight - this.statusBarHeight - 40
        //解析电子书,生成Book对象
        this.book = new Epub(url);
        //通过Book.renderTo生成Rendition对象（对象名不唯一），第一个参数是div的id
        this.rendition = this.book.renderTo("read", {
            flow: "scrolled-doc",
            width: window.innerWidth,
            height: this.readHeight,
        });

        //章节切换
        this.rendition.on("rendered", (section) => {
            window.frames[0].focus()
            this.showPrev = section.prev() ? true : false;
            this.showNext = section.next() ? true : false;
        });

        //pc 键盘左右切换章节
        this.rendition.on("keydown", (event) => {
            if (event.code == "ArrowRight") {
                this.rendition.next()
            }

            if (event.code == "ArrowLeft") {
                this.rendition.prev()
            }
        });


        window.onkeydown = (event) => {
            if (event.code == "ArrowRight") {
                this.rendition.next()
            }

            if (event.code == "ArrowLeft") {
                this.rendition.prev()
            }
        }

        //获取Theme对象
        this.themes = this.rendition.themes;

        this.book.ready
            .then(() => {
                this.bookAvailable = true;
                this.currentReadStartTime = this.$moment().format(
                    "YYYY-MM-DD HH:mm:ss"
                );
                //跳转到上次的位置
                if (this.bookCfi) {
                    this.jumpLast(this.bookCfi);
                }
                //获取在线配置
                this.getConfig();
                //生成目录
                this.navigation = this.book.navigation;
                return this.book.locations.generate();
            })
            .then((result) => {
                this.locations = this.book.locations;
                if (this.rendition.location) {
                    this.setProgress(this.rendition.location.start.cfi);
                    this.page =
                        this.rendition.book.locations.locationFromCfi(
                            this.rendition.location.start.cfi
                        ) + 1;
                }
            });

        this.rendition.on("locationChanged", (e) => {
            this.closeTextMenus();
            if (e.start) {
                this.setProgress(e.start);
            }

            switch (this.pageChange) {
                case 0:
                    this.page =
                        this.rendition.book.locations.locationFromCfi(e.start) + 1;
                    break;
                case 1:
                    if (this.page > 1) {
                        this.page--;
                    }
                    this.pageChange = 0;
                    break;
                case 2:
                    if (!this.isLastPage) {
                        this.page++;
                    }
                    this.pageChange = 0;
                    break;
            }

            this.getIsLastPage(e);
        });

        this.rendition.on("displayed", (e) => {
            if (this.isFirstLoad) {
                this.handleBookReady();
                setInterval(() => {
                    this.hasReadMin++;
                }, 60000);
                this.isFirstLoad = false;
                this.totalPage = this.rendition.book.locations.total;

                this.rendition.themes.default({
                    "::selection": {
                        background: "rgba(77, 159, 124, 0.4)",
                    },
                });
                this.getAudioUrls(this.bookId)
            }
        });

        this.rendition.on("selected", (cfiRange, contents) => {
            this.selectedCfi = cfiRange;
            this.contents = contents;
            this.noteInputFrom = 0;
            this.handleSelectEnd();
        });

        this.rendition.on("click", () => {
            setTimeout(() => {
                if (this.isMarkClick == false) {
                    if (this.selectedCfi != null) {
                        this.hideBar();
                    } else {
                        this.toggleBar();
                    }

                    if (
                        !this.contents ||
                        !this.contents.window.getSelection().toString()
                    ) {
                        this.closeTextMenus();
                    }
                }

                this.isMarkClick = false;
            }, 100);
        });

        this.rendition.on("markClicked", (cfiRange, data, contents) => {
            this.isMarkClick = true;
            this.handleMarkClicked(cfiRange, data.contents);
        });
        this.rendition.display();
    }

    showPCTextMenus(cfiRange, marked) {
        this.textMenu.isUnderLined = marked;
        this.textMenu.x = window.innerWidth;
        this.textMenu.y = window.innerHeight / 2 - 50;
        this.textMenu.showTextMenu = true;
        this.selectedText = this.getNoteString(cfiRange);
    }

}

const util = new TeacherReaderUtil()
export default util;