<template>
  <v-bottom-sheet
    overlay-opacity="0"
    class="pa-0 ma-0"
    scrollable
    max-width="900px"
    v-model="visible"
  >
    <v-card class="pa-0  pb-6 card">
      <v-row class="pa-0 ma-3" justify="space-between">
        <v-row class="pa-0 ma-0">
          <v-avatar class="ml-n2 pa-0">
            <v-img
              :src="headImg"
              min-width="30px"
              min-height="30px"
              max-height="30px"
              max-width="30px"
              aspect-ratio="1"
            ></v-img>
          </v-avatar>
          <p class="f16 common--text font-weight-bold  my-auto mx-2">
            {{ teacherName }}
          </p>
        </v-row>
        <v-btn icon @click="editBtnClickHandler" color="primary">编辑</v-btn>
      </v-row>
      <p class="f16 common--text mx-4">{{ note.note }}</p>
      <p class="f14 minor--text mx-4">{{ createAtStr }}</p>
      <reader-bottom-note-comments
        :comments="comments"
      ></reader-bottom-note-comments>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import { getTeacherInfo } from "@/api/teacher";
import { getTeacherReaderComments } from "@/api/library";
import ReaderBottomNoteComments from "@/views/common/web/reader/reader-note/reader-bottom-note-comments";
export default {
  name: "teacher-reader-bottom-note",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    annotation: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      teacherName: "",
      headImg: "",
      comments: [],
    };
  },
  inject: ["setComments"],
  created() {
    getTeacherInfo().then((res) => {
      this.teacherName = res.data.name;
      this.headImg = this.resHost + res.data.img;
    });
  },
  methods: {
    editBtnClickHandler() {
      this.$emit("editNote", this.annotation);
    },
    getCommentList() {
      const { id } = this.annotation.data;
      getTeacherReaderComments(id).then((res) => {
        this.comments = res.comments;
        this.setComments(this.comments);
      });
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.getCommentList();
      }
    },
  },
  computed: {
    visible: {
      get() {
        return this.dialog;
      },
      set(v) {
        this.$emit("update:dialog", v);
      },
    },
    note() {
      if (this.annotation != null && this.annotation.data != null) {
        return this.annotation.data;
      } else {
        return {};
      }
    },
    createAtStr() {
      const { createdAt, updatedAt } = this.note;
      var timeInt = createdAt;
      var headStr = "发布";
      if (updatedAt != createdAt) {
        timeInt = updatedAt;
        headStr = "修改";
      }
      console.log(this.note);
      var timestamp = new Date(parseInt(timeInt) * 1000);
      let timeStr = this.$moment(timestamp).format("YYYY-MM-DD");
      return headStr + timeStr;
    },
  },
  components: {
    ReaderBottomNoteComments,
  },
};
</script>

<style>
.card {
  border-radius: 16px 16px 0px 0px !important;
}
</style>
